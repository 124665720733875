import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Decoration2 from '../components/Decoration/Decoration-2';

import SvgLinkArrow from '../assets/images/vectors/icons/link-arrow.inline.svg';

import '../assets/styles/pages/learn-and-live-page.scss';
import StartJourneyButton from '../components/StartJourneyButton';

const LearnPage = ({ data }) => {
  const prismicData = data.prismic.allLearn_pages.edges[0].node;
  return (
    <Layout>
      <SEO title="Learn" />
      <div className="learn-and-live-page">
        <FirstSection data={data} prismicData={prismicData} />
        <SecondSection prismicData={prismicData} />
        <ThirdSection data={data} prismicData={prismicData} />
        <FourthSection prismicData={prismicData} />
      </div>
    </Layout>
  );
};

const FirstSection = ({ data: { imageFirstSection }, prismicData: { first_section_title } }) => (
  <section className="first-section">
    <section className="common-first-section">
      <div className="container">
        <div className="cfs__wrapper">
          <div className="cfs__l">
            <div>
              <h1 className="c-h1 cfs__l__title">{RichText.asText(first_section_title)}</h1>

              <Link to="/pricing/" className="c-btn">
                Start your journey
              </Link>
            </div>
          </div>
          <div className="cfs__r">
            <Decoration2 />
            <Img fluid={imageFirstSection.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  </section>
);

const SecondSection = ({ prismicData: { second_section_content } }) => (
  <section className="second-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto">
          {RichText.render(second_section_content)}
        </div>
      </div>
    </div>
  </section>
);
const ThirdSection = ({
  data: {
    imageThirdSectionSHiFT,
    imageThirdSectionMindHub,
    imageThirdSectionWOW,
    imageThirdSectionSENSE,
  },
  prismicData: {
    shift_title,
    shift_description,
    mind_hub_title,
    mind_hub_description,
    wow_title,
    wow_description,
    sense_title,
    sense_description,
  },
}) => (
  <section className="c-section-mt third-section">
    <div className="container">
      {/* <div className="row third-section__row"> */}
      {/*  <div className="col-md-7 third-section__row__image"> */}
      {/*    <Img fluid={imageThirdSectionSHiFT.childImageSharp.fluid} /> */}
      {/*  </div> */}
      {/*  <div className="col-md-5 third-section__row__text"> */}
      {/*    <div className="c-h1">{RichText.asText(shift_title)}</div> */}
      {/*    <p>{RichText.asText(shift_description)}</p> */}
      {/*    <Link to="/shift/" className="c-link--arrow"> */}
      {/*      <span>View features</span> */}
      {/*      <SvgLinkArrow /> */}
      {/*    </Link> */}
      {/*  </div> */}
      {/* </div> */}

      <div className="row third-section__row--reverse">
        <div className="col-md-5 third-section__row--reverse__text">
          <div className="c-h1">{RichText.asText(mind_hub_title)}</div>
          <p>{RichText.asText(mind_hub_description)}</p>
          <Link to="/mind-hub/" className="c-link--arrow">
            <span>Learn more</span>
            <SvgLinkArrow />
          </Link>
        </div>
        <div className="col-md-7 third-section__row--reverse__image">
          <Img fluid={imageThirdSectionMindHub.childImageSharp.fluid} />
        </div>
      </div>

      <div className="row third-section__row">
        <div className="col-md-7 third-section__row__image">
          <Img fluid={imageThirdSectionWOW.childImageSharp.fluid} />
        </div>
        <div className="col-md-5 third-section__row__text">
          <div className="c-h1">{RichText.asText(wow_title)}</div>
          <p>{RichText.asText(wow_description)}</p>
          <Link to="/wow/" className="c-link--arrow">
            <span>Learn more</span>
            <SvgLinkArrow />
          </Link>
        </div>
      </div>

      <div className="row third-section__row--reverse">
        <div className="col-md-5 third-section__row--reverse__text">
          <div className="c-h1">{RichText.asText(sense_title)}</div>
          <p>{RichText.asText(sense_description)}</p>
        </div>
        <div className="col-md-7 third-section__row--reverse__image">
          <Img fluid={imageThirdSectionSENSE.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  </section>
);
const FourthSection = ({ prismicData: { fourth_section_title } }) => (
  <section className="c-section-mt fourth-section">
    <div className="container">
      <div className="row">
        <div className="col-12 ">
          <div className="fourth-section__bg">
            <div className="fourth-section__wrapper">
              <div className="c-h2 text-white ">{RichText.asText(fourth_section_title)}</div>
              <StartJourneyButton text="Sign up now" styleName="c-btn--3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  query {
    prismic {
      allLearn_pages {
        edges {
          node {
            first_section_title
            second_section_content
            shift_title
            shift_description
            mind_hub_title
            mind_hub_description
            wow_title
            wow_description
            sense_title
            sense_description
            fourth_section_title
          }
        }
      }
    }
    imageFirstSection: file(relativePath: { eq: "photos/learn/image-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 816, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageThirdSectionSHiFT: file(relativePath: { eq: "photos/learn/image SHiFT.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageThirdSectionMindHub: file(relativePath: { eq: "photos/learn/image Mind Hub.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageThirdSectionWOW: file(relativePath: { eq: "photos/learn/image WOW.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageThirdSectionSENSE: file(relativePath: { eq: "photos/learn/image SENSE.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default LearnPage;
